<template>
    <div>
        <van-sticky>
            <title-bar2 title="联系客服" />
        </van-sticky>
        <van-cell-group title="商家联系方式">
            <van-cell>
                <div>① 扫码添加好友，联系商家</div>
            </van-cell>
            <van-cell>
                <van-image width="200" height="200" fit="contain" :src="proxyContactImg" />
            </van-cell>
            <van-cell>
                <div>② 商家联系方式：</div>
            </van-cell>
            <van-cell>
                <div>{{ proxyContactInfo }}</div>
            </van-cell>
        </van-cell-group>
    </div>
</template>
  
<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
    },
    data() {
        return {
            proxyContactInfo: null,
        };
    },
    computed: {
        proxyContactImg() {
            return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=1`;
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {

        },
        //获取代理信息
        getProxyInfo() {
            var model = {
                ProxyCode: this.$store.state.proxyCode,
            };
            this.$axios
                .post(Config.proxy.contactInfo, model)
                .then((res) => {
                    this.proxyContactInfo = res.data.Data.ContactInfo;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
    },
    mounted() {
        this.getRoutParams();
        this.getProxyInfo();
    },
};
</script>

<style scoped>
::v-deep .van-cell-group__title {
    background-color: white;
}
</style>