<template>
    <div class="content-box">
        <div class="content-left">
            <div class="content-text">① 扫码添加好友，联系商家</div>
            <img class="content-img" :src="proxyContactImg" />
            <div class="content-text">② 商家联系方式</div>
            <div class="content-text">{{ proxyContactInfo }}</div>
        </div>
        <div class="content-right">
            <school-hot></school-hot>
        </div>
    </div>
</template>
    
<script>
import SchoolHot from "@/components/PC/SchoolHot";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        SchoolHot,
    },
    data() {
        return {
            proxyContactInfo: null
        };
    },
    computed: {
        proxyContactImg() {
            return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=1`;
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {

        },
        //获取代理信息
        getProxyInfo() {
            var model = {
                ProxyCode: this.$store.state.proxyCode,
            };
            this.$axios
                .post(Config.proxy.contactInfo, model)
                .then((res) => {
                    this.proxyContactInfo = res.data.Data.ContactInfo;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
    },
    mounted() {
        this.getRoutParams();
        this.getProxyInfo();
    }
};
</script>
<style scoped>
.content-box {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.content-left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.content-img {
    width: 200px;
    height: 200px;
    margin: 20px 0px;
}

.content-text {
    color: gray;
    font-size: 14px;
    margin-top: 10px;
}
</style>